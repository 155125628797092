import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import "./news.css";

function NewsListing() {
    const [news, setNews] = useState([]);

    useEffect(() => {
        fetch('/assets/json/news.json')
            .then(response => response.json())
            .then(result => setNews(result))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <>
            <section className="news-banner">
                <h1 className="p-4 text-center">News Listing</h1>
            </section>
            <section className="news-wrapper common-section-spacing">
                <div className="container">
                    <ul className="news-listing">
                        {news.map((data) => (
                            <li className="news">
                                <a key={data.id} href={'/news/' + data.url}>
                                    <img src={data.imageUrl} className="img-fluid"></img>
                                    <div className="news-info">
                                        <p className="tag-name">
                                            {data.tag.map((tag) => (
                                                <span>{tag}</span>
                                            ))}
                                        </p>
                                        <h5>{data.name}</h5>
                                        <p className="date">{data.date}</p>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        </>
    )
}

export default NewsListing