import React, { useState, useEffect } from "react";

import "./footer.css";


function Footer() {
  const [services, setServices] = useState([]);
   
let date1 = new Date("01/01/2016").getFullYear();
let date2 = new Date().getFullYear();

let DifferenceInYears = date2 - date1;
  

  useEffect(() => {
    fetch("/assets/json/services.json")
      .then((response) => response.json())
      .then((result) => setServices(result))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <footer className="text-center text-md-start text-white">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-lg-3 col-xl-3 mx-auto my-5">
            <h2 className="text-uppercase mb-4 font-weight-bold">
              Global Logistics
            </h2>
            <p>
              At Global Logistics and Shipping Pvt. Ltd., we're more than just
              freight forwarders. We're your trusted partner in navigating the
              complex world of international logistics. For over {(String(+DifferenceInYears).charAt(0) == DifferenceInYears)?'0'+DifferenceInYears:DifferenceInYears} years,
              we've been helping businesses of all sizes connect their supply
              chains to global markets, with seamless efficiency and unwavering
              commitment.
            </p>
          </div>

          <hr className="w-100 clearfix d-md-none" />

          <div className="col-lg-4 col-md-7 mx-auto my-5">
            <h4 className="text-uppercase mb-4 font-weight-bold">Services</h4>
            <div className="footer-links">
              {services.map((service) => (
                <p key={service.id}>
                  <a className="text-white" href={"/services/" + service.url}>
                    {service.name}
                  </a>
                </p>
              ))}
            </div>
          </div>

          <div className="col-md-3 mx-auto my-5">
            {/* Logo component */}
            <img
              src="/assets/images/logo.png"
              alt="Global Logistics"
              className="img-fluid logo"
            />
            <div className="address mb-4">
              <p>
                <span className="labels">Office Address :</span> House No. #98,
                Road No. #11, Banani, Block - C, 5th Floor, Dhaka-1213,
                Bangladesh
              </p>
              <p>
                <span className="labels">Phone :</span> +8802 41081056,
                +880241081057
              </p>
              <p>
                <span className="labels">Email : </span>
                <a
                  href="mailto:admin@glsbd.co"
                  class="link"
                  style={{ color: "white", textDecoration: "none" }}
                  target="_new"
                >
                  admin@glsbd.co
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center p-3">
        © {new Date().getFullYear()} Copyright: Global Logistics and Shipping
        Pvt Ltd
      </div>
    </footer>
  );
}

export default Footer;
