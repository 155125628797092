// TrackingForm.js
import React, { useState, useEffect } from 'react';
import trackingImage from "./trackorder.jpg";
import ordertrackingcontent from "../../ordertrackingcontent.jpg"
import './tracking-form.css';

function TrackingForm() {
    const [selectedOption, setSelectedOption] = useState('');
    const [refNumber, setRefNumber] = useState('');
    const [services, setServices] = useState([]);
    const [errors, setErrors] = useState({ selectedOption: '', refNumber: '' });

    useEffect(() => {
        fetch('/assets/json/services.json')
            .then(response => response.json())
            .then(result => setServices(result))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleSubmit = () => {
        let newErrors = { selectedOption: '', refNumber: '' };

        // Validate selected option
        if (!selectedOption) {
            newErrors.selectedOption = "Please select a tracking type";
        }

        // Validate reference number
        if (!refNumber) {
            newErrors.refNumber = "Please enter a reference number";
        }

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error !== '')) {
            // There are validation errors, do not proceed
            return;
        }

        // Proceed with tracking logic...
        let trackingURL = '';

        switch (selectedOption) {
            case 'HBL':
                trackingURL = `http://www.efreightsuite.com/webtools/trackntrace/hbldisplay.aspx?company_code=D5M0&hbl_no=${refNumber}`;
                break;
            case 'Container':
                trackingURL = `http://www.efreightsuite.com/webtools/eContnr/home_container_main.aspx?company_code=SaaSId&container_no=${refNumber}`;
                break;
            case 'HAWB':
                trackingURL = `http://www.efreightsuite.com/webtools/trackntrace/hawb_result.aspx?company_code=D5M0&hawb_no=${refNumber}`;
                break;
            case 'ShippingBill':
                trackingURL = `http://www.efreightsuite.com/webtools/trackntrace/shipping_bill_display.aspx?company_code=D5M0&shipping_bill_no=${refNumber}`;
                break;
            case 'BookingNum':
                trackingURL = `http://www.efreightsuite.com/webtools/trackntrace/booking_result.aspx?company_code=D5M0&booking_no=${refNumber}&remark_status=true`;
                
                break;
            default:
                break;
        }
        window.open(trackingURL, '_blank');
        // Redirect to the external URL
        // window.location.href = trackingURL;
    };

    return (
        <div>
            <section className="tracking-banner">
                <h1 className="p-4 text-center">Tracking Form</h1>
            </section>

            <section className="tracking-wrapper py-md-5 my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="text-primary">
                                Track your Order
                            </h4>
                            <hr />

                            <div className="row form-container">
                                <div className="col-md-6 mb-4">
                                    <label className="form-label">
                                        Select Tracking Type:
                                        <select className="form-control" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                            <option value="">Select...</option>
                                            <option value="HBL">HBL Tracking</option>
                                            <option value="Container">Container Tracking</option>
                                            <option value="HAWB">HAWB Tracking</option>
                                            <option value="ShippingBill">Shipping Bill Tracking</option>
                                            <option value="BookingNum">Booking Number Tracking</option>
                                        </select>
                                        {errors.selectedOption && <p className="error-message">{errors.selectedOption}</p>}
                                    </label>
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label className="form-label">
                                        Reference Number:
                                        <input type="text" className="form-control" value={refNumber} onChange={(e) => setRefNumber(e.target.value)} />
                                        {errors.refNumber && <p className="error-message">{errors.refNumber}</p>}
                                    </label>
                                </div>
                                <div className="col-12">
                                    <button onClick={handleSubmit} className="btn btn-primary col-md-6">Click to track</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <img src={ordertrackingcontent} className="img-fluid" alt="Tracking" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <h3 className="services-section-title">We offer a wide range of services to meet your needs, including:</h3>
            </section>

            <section className="home-page">
                {services.slice(0, 5).map((service) => (
                    <div className="service-card" key={service.id}>
                        <a className="service-name" key={service.id} href={'/services/' + service.url} >
                            <div className="circle-border">
                                <div className="circle-content">
                                    <img src={service.imageUrl} alt={service.name} className="circle-logo" />
                                    <p className="service-name">{service.name}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </section>

            <section className="home-page">
                {services.slice(5, 10).map((service) => (
                    <div className="service-card" key={service.id}>
                        <a className="service-name" key={service.id} href={'/services/' + service.url} >
                            <div className="circle-border">
                                <div className="circle-content">
                                    <img src={service.imageUrl} alt={service.name} className="circle-logo" />
                                    <p className="service-name">{service.name}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </section>
        </div>
    );
}

export default TrackingForm;
