import React, { useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
import './home.css';

function Home() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const [services, setServices] = useState([]);

    useEffect(() => {
        fetch('/assets/json/services.json')
            .then(response => response.json())
            .then(result => setServices(result))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    /* const [news, setnews] = useState([]);
    
    useEffect(() => {
        fetch('/assets/json/news.json')
            .then(response => response.json())
            .then(result => setnews(result))
            .catch(error => console.error('Error fetching data:', error));
    }, []); */

    const slides = ['/assets/images/banner1.webp','/assets/images/banner2.webp',,'/assets/images/banner3.jpg','/assets/images/banner4.webp','/assets/images/banner5.webp','/assets/images/banner6.jpg'] 
    return (
        <>
            <Carousel activeIndex={index} onSelect={handleSelect}>
                {slides.map((slide) => (
                    <Carousel.Item>
                        <img className="img-fluid" src={slide} alt="Banner"></img>
                    </Carousel.Item>
                ))}
            </Carousel>
            <section className="about-wrapper common-section-spacing">
                <div className="container">
                    <h3 className="section-title">About Global Logistics</h3>
                    <div className="row">
                        <div className="col-lg-6">
                            <div>
                            <p>At Global Logistics and Shipping Pvt. Ltd., we're more than just freight forwarders. We're your trusted partner in navigating the complex world of international logistics. For over [Number] years, we've been helping businesses of all sizes connect their supply chains to global markets, with seamless efficiency and unwavering commitment.</p>
{/*
<p>We offer a wide range of services to meet your needs, including:</p>
<ul>
  <li>Air freight</li>
  <li>Ocean freight</li>
  <li>Land transportation</li>
  <li>Warehousing and distribution</li>
  <li>Customs clearance</li>
  <li>Project cargo</li>
  <li>PO Management</li>
  <li>Pick and Pack</li>
  <li>Quality Control & Inspection</li>
  <li>Cross Border Movement</li>
                </ul> */}

<p>We also have a team of experienced professionals who can help you choose the right shipping solution for your needs. We're committed to providing you with the best possible service, and we're always here to help you get your cargo where it needs to go.</p>

<p>If you're looking for a reliable and experienced freight forwarder, contact Global Logistics and Shipping Pvt. Ltd. today. We're confident that we can help you achieve your shipping goals.</p>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src="/assets/images/about-image.jpg" className="img-fluid" alt="About"></img> 
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <h3 className="services-section-title">We offer a wide range of services to meet your needs, including:</h3>
            </section>
            
            <section className="home-page">
                {services.slice(0,5).map((service) => (
                     <div className="service-card" key={service.id}>
                     <a className="service-name" href={'/services/' + service.url}>
                         <div className="circle-border">
                         <div className="circle-content">
                            <img src={service.imageUrl} alt={service.name} className="circle-logo" />
                             <p className="service-name">{service.name}</p>    
                         </div>
                     </div>  
                     </a>
                 </div>
                ))}
               
            </section>

            <section className="home-page">
                {services.slice(5,10).map((service) => (
                     <div className="service-card" key={service.id}>
                     <a className="service-name" href={'/services/' + service.url}>
                         <div className="circle-border">
                         <div className="circle-content">
                            <img src={service.imageUrl} alt={service.name} className="circle-logo" />
                             <p className="service-name">{service.name}</p>    
                         </div>
                     </div>  
                     </a>
                 </div>
                ))}
               
            </section>


           {/* <section className="news-wrapper common-section-spacing">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <h3 className="section-title mb-0">Notices and News</h3>
                        <a href="/news" className="btn btn-outline-primary">See All News</a>
                    </div>
                    <ul className="news-listing">
                        {news.slice(0, 3).map((data) => (
                            <li className="news">
                                <a key={data.id} href={'/news/' + data.url}>
                                    <img src={data.imageUrl} className="img-fluid"></img>
                                    <div className="news-info">
                                        <p className="tag-name">
                                            {data.tag.map((tag) => (
                                                <span>{tag}</span>
                                            ))}
                                        </p>
                                        <h5>{data.name}</h5>
                                        <p className="date">{data.date}</p>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </section> */}
        </>
    );
}

export default Home;