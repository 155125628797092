import React, { useRef, useState } from "react";
import validator from "validator";
// import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios"; // Corrected import statement
import "./contact-us.css";
import Swal from 'sweetalert2';


function ContactUs() {
  const form = useRef(); // Declare the form ref here
  const [submitted, setSubmitted] = useState(false);
  const [submissionStatus,setSubmissionStatus] = useState(null);


  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    message: "",
  });

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    message: "",
  });



  const handleSubmit = async (event) => {
    event.preventDefault();
    Swal.showLoading();
    const formData = new FormData(event.target);
    
    try {
      const response = await axios.post('/global-api/Submit',formData)
      if (response.data.success) {
        setSubmissionStatus("success");
        Swal.close();
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          contact: "",
          message: "",
        }, 2000);
        
        Swal.fire({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
       else {
      
        setSubmissionStatus("error");
     
      }
    }  catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionStatus("error");
      setErrors(error.response.data.data);
      let x = '';
      const entries = Object.entries(error.response.data.data);

      entries.map(([key, value]) => {
        console.log(key+" "+value);
        x+=key+":"+value+"<br>";
      });
  
      Swal.fire({
        title: 'Error!',
        html:  error.response.data.message+"<br>"+x,
        icon: 'error',
        confirmButtonText: 'OK',
      });
     
    }

    
  };
  
  const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });
  }


  return (
    <>
      <section className="contact-banner">
        <h1 className="p-4 text-center">Contact Us</h1>
      </section>

      <section className="contact-wrapper py-md-5 my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="address mb-4">
                <p>
                  <span className="labels">Office Address :</span> House No.
                  #98, Road No. #11, Banani, Block - C, 5th Floor, Dhaka-1213,
                  Bangladesh
                </p>
                <p>
                  <span className="labels">Phone :</span> +8802 41081056,
                  +880241081057
                </p>
                <p>
                  <span className="labels">Email : </span>
                  <a
                    href="mailto:admin@glsbd.co"
                    class="link"
                    style={{ textDecoration: "none", color: "inherit" }}
                    target="_new"
                  >
                    admin@glsbd.co
                  </a>
                </p>
              </div>

              <h4 className="text-primary">
                Want to Know More?? Drop Us a Mail
              </h4>
              <hr />

              <form onSubmit={handleSubmit} className="row form-container">
                <div className="col-md-6 mb-4">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                  {errors.first_name && (
                    <p className="error-message">{errors.first_name}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                  {errors.last_name && (
                    <p className="error-message">{errors.last_name}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <input
                    className="form-control"
                    type="Email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Contact Number"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                  />
                  {errors.contact && (
                    <p className="error-message">{errors.contact}</p>
                  )}
                </div>
                <div className="col-12 mb-4">
                  <textarea
                    name="message"
                    className="form-control"
                    placeholder="Your message here..."
                    value={formData.message}
                    onChange={handleChange}   
                  ></textarea>
                  {errors.message && (
                    <p className="error-message">{errors.message}</p>
                  )}
                </div>

                {/* <ReCAPTCHA
                                sitekey="AIzaSyBjmS-BK-_z1ylrEfjtQZSnJk_zMUETHiw"
                                onChange={onChange}
                                style={{ marginBottom: "20px" }}
                            /> */}
                {errors.captcha && (
                  <p className="error-message">{errors.captcha}</p>
                )}

                <div className="col-12">
                  <input
                    type="submit"
                    value="Send"
                    className="btn btn-primary col-md-6"
                  />
                </div>
              </form>

              {submitted && (
                <div className="success-popup">
                  <p>Your message has been submitted successfully!</p>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3650.7725750988952!2d90.40035347479322!3d23.79111158721326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDQ3JzI4LjAiTiA5MMKwMjQnMTAuNSJF!5e0!3m2!1sen!2sin!4v1705302993712!5m2!1sen!2sin"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Office Location"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
