import React from "react";
//import missionImage from "./our-mission.jpg";
//import valuesImage from "./our-values.jpg";
import img22 from "./img22.jpg";
import img3 from "./img3.webp";
import img4 from "./img-4.jpg";
import ourmission from "./ourmission.jpg";
//import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './about-us.css';

function AboutUs() {
    return (
        <>
            <section className="about-banner">
                <h1 className="p-4 text-center">About Us</h1>
            </section>

            <section className="vision-mission-section">
                <div className="container">
                     {/* Mission */}
                    <div className="row py-md-5 my-5 align-items-center justify-content-between">
                        <div className="col-md-5">
                            <h3 className="section-title">Our Mission</h3> 
                            <p className="about-description">
                            To seamlessly bridge the world's commerce, enabling businesses of all sizes to thrive through exceptional cargo movement and personalized service. We are driven by:
                                <ul>
                                    <li><b>Unwavering commitment to customer satisfaction:</b> Providing flexible, responsive solutions that anticipate and exceed your needs.</li>
                                    <li><b>Operational excellence:</b> Employing cutting-edge technology and expertise to ensure secure, efficient, and timely delivery of your cargo.</li>
                                    <li><b>Global reach:</b> Building a network of trusted partners, extending our reach across continents and offering diverse transport options.</li>
                                    <li><b>Cost-effective solutions:</b> Optimizing routes and logistics to offer competitive pricing without compromising on quality.</li>
                                    <li><b>Sustainability:</b> Minimizing our environmental impact through innovative approaches and green initiatives.</li>
                                    <li><b>Integrity and transparency:</b> Cultivating a culture of honesty, ethical practices, and clear communication in every interaction.</li>
                                </ul>          
                            </p>
                        </div>
                        
                        <div className="col-md-6">
                            <img src={ourmission} className="img-fluid" alt="About"></img> 
                        </div>
                    </div>
                     {/* Values */}
                    <div className="row py-md-5 my-5 align-items-center justify-content-between flex-md-row-reverse">
                        <div className="col-md-5">
                            <h3 className="section-title">Our Values</h3>
                            <p className="about-description">
                            
                            Through our dedication to these values, we will:
                                <ul>
                                    <li>Foster global trade and economic growth.</li>
                                    <li>Empower businesses to reach new markets and expand their reach.</li>
                                    <li>Create positive experiences for our customers, partners, and employees.</li>
                                    <li>Be recognized as a leader in the freight forwarding industry, synonymous with reliability, innovation, and customer focus.</li>
                                </ul>
                            
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src={img22} className="img-fluid" alt="Values"></img> 
                        </div>
                    </div>
                </div>
            </section>

                        {/* Work Philosophy */}
            <section className="vision-mission-section">
                <div className="container">
                    <div className="row py-md-5 my-5 align-items-center justify-content-between">
                        <div className="col-md-5">
                            <h3 className="section-title">Work Philosophy </h3>
                            <p className="about-description">
                                At Global Logistics & Shipping Pvt. Ltd., we believe in more than just moving cargo. We believe in connecting businesses, cultures, and dreams. This philosophy guides everything we do, from building customer relationships to navigating complex logistics. Here are the core principles that drive our team:
                                <ol>
                                    <li><b>We are One Team, One Goal:</b> We are not just colleagues; we are a family united by a shared passion for excellence. We celebrate each other's successes, support each other during challenges, and work together seamlessly to achieve our common goals.</li>
                                    <li><b>Customer Focus is Our Compass:</b> Our customers are at the heart of everything we do. We are committed to understanding their needs, exceeding their expectations, and building long-term partnerships based on trust and mutual respect.</li>
                                    <li><b>Innovation Fuels Our Journey:</b> We constantly seek new and better ways to do things. We embrace technology, explore creative solutions, and stay ahead of the curve to ensure the most efficient and effective service for our customers.</li>
                                    <li><b>Integrity is Our Anchor:</b> We operate with unwavering honesty and transparency in everything we do. We build trust through ethical practices, reliable service, and open communication.</li>
                                </ol>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src={img3} className="img-fluid" alt="Work Philosophy" /> 
                        </div>
                    </div>
                </div>
            </section>

           
            <section className="vision-mission-section">
                <div className="container">
                    <div className="row py-md-5 my-5 align-items-center justify-content-between flex-md-row-reverse">
                        <div className="col-md-5">
                           
                            <p className="about-description">
                                <ol start="5">
                                    <li><b>We Value Growth, Both Personal and Professional:</b> We believe in providing opportunities for our employees to learn, grow, and reach their full potential. We invest in training, development, and a nurturing work environment that fosters continuous improvement.</li>
                                    <li><b>Sustainability is Our Responsibility:</b> We are committed to minimizing our environmental impact while maximizing our positive contribution to the world. We actively seek sustainable solutions and operate with a deep respect for the planet.</li>
                                    <li><b>We Play to Win, but with Heart:</b> We are passionate about success and driven to achieve our goals. However, we believe in winning with integrity, empathy, and a commitment to doing what is right, even when it's challenging.</li>
                                </ol>
                                These are not just words on a page; they are the values that guide every decision we make and every action we take. They are the beating heart of Global Logistics & Shipping Pvt. Ltd., and they are what sets us apart.
                                Together, we are more than just a freight forwarding company. We are a team of passionate professionals, dedicated to connecting the world and making a positive difference.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src={img4} className="img-fluid" alt="Work Philosophy 2"/> 
                        </div>
                    </div>
                </div>
            </section>


           {/* <section className="quotes-wrapper">
                <div className="container">
                    <quote>  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </quote>
                </div>
            </section>

        <section className="timeline-wrapper">
                <div className="container">
                    <h3 className="section-title">About Company Profile</h3>
                    <p className="about-description mb-5">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                    <div class="timeline">
                        <div class="card-item-pcblue">
                            <span aria-hidden="true" class="circle-custom">
                                <FontAwesomeIcon icon={faTwitter} />
                            </span>
                            <div class="subcard">
                                <strong>September 2023</strong>
                                <br />Contractor is selected and initial work gets underway
                            </div>
                        </div>
                        <div class="card-item-pcblue">
                            <span aria-hidden="true" class="circle-custom">
                                <FontAwesomeIcon icon={faTwitter} />
                            </span>
                            <div class="subcard">
                                <strong>September 2023</strong>
                                <br />Contractor is selected and initial work gets underway
                            </div>
                        </div>
                        <div class="card-item-pcblue">
                            <span aria-hidden="true" class="circle-custom" >
                                <FontAwesomeIcon icon={faTwitter} />
                            </span>
                            <div class="subcard">
                                <strong>September 2023</strong>
                                <br />Contractor is selected and initial work gets underway
                            </div>
                        </div>
                        <div class="card-item-pcblue">
                            <span aria-hidden="true" class="circle-custom">
                                <FontAwesomeIcon icon={faTwitter} />
                            </span>
                            <div class="subcard">
                                <strong>September 2023</strong>
                                <br />Contractor is selected and initial work gets underway
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bottom-link pb-5 my-3 ">
                <div className="container text-center">
                    <button className="btn btn-outline-primary btn-lg">Download Presentation</button>
                </div>
            </section>*/}
        </>
    );
}

export default AboutUs;