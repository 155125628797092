import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./serviceDetail.css";
import img1 from "./img1.png";
import img2 from "./img2.jpg";
import img3 from "./img3.jpg";
import img4 from "./img4.jpg";
import img5 from "./img5.jpg";
import img6 from "./img6.jpg";
import img7 from "./img7.jpg";
import img8 from "./img8.jpg";
import img9 from "./img9.jpg";
import img10 from "./img10.jpg";
import photo1 from "./photo1.jpg";
import photo2 from "./photo2.jpg";
import photo3 from "./photo3.jpg";
import photo4 from "./photo4.jpg";
import photo5 from "./photo5.jpg";
import photo6 from "./photo6.jpg";
import photo7 from "./photo7.jpg";
import photo8 from "./photo8.jpg";
import photo9 from "./photo9.jpg";
import photo10 from "./photo10.jpg";

function ServicesDetail() {
  const { serviceName } = useParams();
  const [services, setServices] = useState([]);
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
  const serviceIndex = services.findIndex(
    (service) => service.url === serviceName
  );
  const serviceImage = images[serviceIndex % images.length];
  const photos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
  ];
  const serviceIdx = services.findIndex(
    (service) => service.url === serviceName
  );
  const servicePhoto = photos[serviceIdx % photos.length];

  useEffect(() => {
    fetch("/assets/json/services.json")
      .then((response) => response.json())
      .then((result) => setServices(result))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    fetch("/assets/json/services.json")
      .then((response) => response.json())
      .then((result) =>
        setSelectedServices(result.filter((obj) => obj.url === serviceName)[0])
      )
      .catch((error) => console.error("Error fetching data:", error));
  });

  return (
    <>
      <section
        className="service-banner"
        style={{ backgroundImage: `url(${serviceImage})` }}
      >
        <h1 className="p-4 text-center">{selectedServices.name}</h1>
      </section>
      <section className="main-content my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: selectedServices.description,
                }}
              ></div>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: selectedServices.data }}
              ></div>
            </div>
            <div className="col-md-4">
              <div className="sidebar-service-navigation">
                <h3 className="mb-1 pb-3 border-bottom">Other Services</h3>
                <ul className="sidebar-services">
                  {services.map((service) => (
                    <li className="service">
                      <a
                        key={service.id}
                        href={"/services/" + service.url}
                        className={
                          selectedServices.url === service.url ? "active" : ""
                        }
                      >
                        {service.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesDetail;
