import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import "./news-detail.css";

function NewsDetail() {
    const { newsName } = useParams();
    const [news, setNews] = useState([]);

    useEffect(() => {
        fetch('/assets/json/news.json')
            .then(response => response.json())
            .then(result => setNews(result))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const [selectedNews, setselectedNews] = useState([]);

    useEffect(() => {
        fetch('/assets/json/news.json')
            .then(response => response.json())
            .then(result => setselectedNews(result.filter(obj => obj.url === newsName)[0]))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-8">
                        <div className="mx-auto text-secondary">
                            <div className="news-badges">
                                {selectedNews.tag && selectedNews.tag.length && selectedNews.tag.map((t) => (
                                    <small>{t}</small>
                                ))}
                            </div>
                            <h1 className="font-weight-bold text-primary">{selectedNews.name}</h1>
                        </div>
                        <img className="w-100 my-3 mb-4" src={selectedNews.imageUrl} />

                        <div className="content mx-auto text-secondary" dangerouslySetInnerHTML={
                            { __html: selectedNews.data }
                        }>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="sidebar-service-navigation">
                            <h3 className="mb-1 pb-3 border-bottom">Recent News</h3>
                            <ul className="sidebar-services">
                                {news.map((news) => (
                                    <li className={selectedNews.url == news.url ? 'd-none service' : 'service'}>
                                        <a key={news.id} href={'/news/' + news.url} >
                                            {news.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default NewsDetail