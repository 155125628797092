import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { Component } from "react";
import Header from './components/header/header';
import Footer from './components/footer/footer';
import AboutUs from './views/about-us/about-us';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Home from './views/home/home';
import ServicesDetail from './components/service-details/service-details';
import ContactUs from './views/contact-us/contact-us';
import NewsDetail from './components/news-detail/news-detail';
import NewsListing from './views/news/news';
import TrackingForm from './views/tracking/tracking-form';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} exact></Route>
          <Route path='/about-us' element={<AboutUs />} exact></Route>
          <Route path="/services/:serviceName" element={<ServicesDetail />} />
          <Route path='/contact-us' element={<ContactUs />} exact></Route>
          <Route path="/news/:newsName" element={<NewsDetail />} />
          <Route path="/news" element={<NewsListing />} /> 
          <Route path='/tracking-form' element={<TrackingForm />} exact></Route>
        </Routes>
        <Footer />
      </Router>

    </div>
  );
}

export default App;
