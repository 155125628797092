import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
//import GlobalLogistics from "/assets/pdf/GlobalLogistics.pdf";
import "./header.css";

function Header() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetch("/assets/json/services.json")
      .then((response) => response.json())
      .then((result) => setServices(result))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleDownload = () => {
    const pdfPath = "/assets/pdf/GlobalLogistics.pdf";

    // Download link
    const link = document.createElement("a");
    link.download = "GlobalLogistics.pdf";
    link.href = pdfPath;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Navbar expand="lg" className="bg-white" fixed="top">
      <Navbar.Brand href="/">
        <img
          src="/assets/images/logo.png"
          alt="Global Logistics"
          className="img-fluid logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/about-us">About Us</Nav.Link>
          <NavDropdown title="Services">
            {services.map((service) => (
              <NavDropdown.Item
                key={service.id}
                href={"/services/" + service.url}
              >
                {service.name}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          <Nav.Link href="/tracking-form">Track & Trace</Nav.Link>
          <Nav.Link href="/contact-us">Contact Us</Nav.Link>
          <Nav.Link onClick={handleDownload}>Download</Nav.Link>
          <Nav.Link href="/assets/pdf/GlobalLogistics.pdf" target="_blank">STC</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
